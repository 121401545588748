import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n';
import Header from './components/Header';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import SuccessStories from './components/SuccessStories';
import ContactPage from './components/ContactPage';

function App() {
  const { t } = useTranslation(); // Hook para traducciones

  // useEffect(() => {
  //   // Función para inicializar el widget de Calendly
  //   const initializeCalendlyWidget = () => {
  //     // Destruye el widget existente, si lo hay
  //     if (window.Calendly && window.Calendly.destroyBadgeWidget) {
  //       window.Calendly.destroyBadgeWidget();
  //     }

  //     // Inicializa el nuevo widget
  //     window.Calendly.initBadgeWidget({
  //       // url: 'https://calendly.com/jmansillakrino/reunion-45-min',
  //       url: 'https://calendly.com/vprieto/one-to-one-con-victor-hugo',
  //       text: t('calendly.scheduleMeeting'), // Texto traducido
  //       color: '#0069ff',
  //       textColor: '#ffffff',
  //       branding: undefined
  //     });
  //   };

  //   // Cargar el script de Calendly solo si no está ya cargado
  //   if (!window.Calendly) {
  //     const link = document.createElement('link');
  //     link.href = 'https://assets.calendly.com/assets/external/widget.css';
  //     link.rel = 'stylesheet';
  //     document.head.appendChild(link);

  //     const script = document.createElement('script');
  //     script.src = 'https://assets.calendly.com/assets/external/widget.js';
  //     script.async = true;
  //     document.body.appendChild(script);

  //     script.onload = () => {
  //       initializeCalendlyWidget(); // Inicializa el widget después de cargar el script
  //     };
  //   } else {
  //     // Si el script ya está cargado, solo inicializa el widget
  //     initializeCalendlyWidget();
  //   }

  //   // Limpieza para evitar duplicaciones al desmontar el componente
  //   return () => {
  //     if (window.Calendly && window.Calendly.destroyBadgeWidget) {
  //       window.Calendly.destroyBadgeWidget();
  //     }
  //   };
  // }, [t]); // Dependencia para volver a ejecutar si cambia la traducción
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://improvebot.krino.ai/065c58d980082ccfbb6901f39b119abd2b075cf05064b7e7f485fc25ed9ec4f5/5bd422692b7f2008f60fe9f5/?number=+56931402056&message=Quiero%20saber%20m%C3%A1s%20de%20Karpilot&position=bottom-right&css&cta=%F0%9F%8F%86%20%C3%9Anete%20a%20las%20concesionarias%20que%20ya%20venden%20m%C3%A1s%20con%20Karpilot";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blog" element={<SuccessStories />} />
            {/* <Route path="/blog/:slug" element={<BlogPost />} /> */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
